import { faker } from '@faker-js/faker';
import { APIResponseScopeData } from 'api/scopes/getScopeDataAPI/index';
import { scopeBlockMock } from 'api/scopes/getScopeDataAPI/scopeBlockMock';
import { scopeBlockContactDetailsMock } from 'api/scopes/getScopeDataAPI/scopeBlockContactDetailsMock';
import { scopeBlockRiskDomainsMock } from 'api/scopes/getScopeDataAPI/scopeBlockRiskDomainsMock';

const { company, datatype } = faker;

export const getScopeDataMock: (id: string) => APIResponseScopeData = id => {
  return {
    id,
    name: company.bs(),
    scope_data: [
      scopeBlockMock,
      scopeBlockContactDetailsMock,
      scopeBlockRiskDomainsMock,
    ],
    bank_impact: datatype.boolean(),
    intermediary: datatype.boolean(),
    impact: {
      min: 10,
      max: 100,
      level: datatype.number({ min: 10, max: 100 }),
      label: 'Impact',
      value: 'level',
    },
    probability: {
      min: 10,
      max: 100,
      level: datatype.number({ min: 10, max: 100 }),
      label: 'Impact',
      value: 'level',
    },
  };
};
