import { ScopeBlockContactDetails } from 'app/containers/RootScope/types';
import { faker } from '@faker-js/faker';

const { internet, name } = faker;

export const scopeBlockContactDetailsMock: ScopeBlockContactDetails = {
  id: 'contact_details',
  type: 'contact_details',
  fields_list: [
    {
      id: 'relationship_manager',
      type: 'contact',
      label: 'Relationship Manager',
      required: true,
      value: 'N/A',
      fields_list: [
        {
          id: 'relationship_manager_aggregator_button',
          type: 'aggregator_button',
          label: 'CommonBlock/Field|Aggregator',
          required: false,
          value: 'auth0|6315d97f912285a92e063ba9',
        },
        {
          id: 'relationship_manager_email',
          type: 'autocomplete_email',
          label: 'Email',
          placeholder: 'Enter email',
          required: true,
          value: internet.email(),
        },
        {
          id: 'relationship_manager_name',
          type: 'text',
          label: 'Name',
          placeholder: 'Enter name',
          required: true,
          value: name.fullName(),
        },
        {
          id: 'blank_2',
          type: 'blank',
        },
      ],
    },
  ],
};
