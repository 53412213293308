import { faker } from '@faker-js/faker';
import { APIResponseParty } from 'api/thirdParties/getPartyAPI/index';

const { datatype, internet, company, image, address } = faker;

export const getPartyMock: (partyId: string) => APIResponseParty = id => {
  return {
    id,
    status: 'active',
    logo: image.image(86, 86),
    name: company.name(),
    domain: internet.url(),
    location: address.county(),
    is_critical: datatype.boolean(),
    is_followed: datatype.boolean(),

    tabs: [
      { id: 'overview', label: 'Overview' },
      { id: 'insights', label: 'Insights' },
      { id: 'files', label: 'Files' },
    ],
    impact: {
      min: 10,
      max: 100,
      level: datatype.number({ min: 10, max: 100 }),
      label: 'Impact',
      value: 'level',
    },
    probability: {
      min: 10,
      max: 100,
      level: datatype.number({ min: 10, max: 100 }),
      label: 'Probability',
      value: 'level',
    },
  };
};
