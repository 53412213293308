/**
 *
 * useTable
 *
 */
import { CellMod, AbstractTableProps } from './types';

export interface IUseTable extends AbstractTableProps {
  setColspan: (mods?: Array<CellMod>) => number;
  setWidth: (mods?: Array<CellMod>) => string | undefined;
}

export function useTable(props: AbstractTableProps): IUseTable {
  const setColspan = (mods?: Array<CellMod>): number => {
    if (mods?.includes('colspan2')) {
      return 2;
    }
    return 1;
  };

  const setWidth = (mods?: Array<CellMod>): string | undefined => {
    if (mods?.includes('width50')) {
      return '50%';
    }
    if (mods?.includes('width30')) {
      return '30%';
    }
  };

  return { ...props, setColspan, setWidth };
}
