import { ScopeBlockScope } from 'app/containers/RootScope/types';
import { faker } from '@faker-js/faker';

const { random } = faker;

export const scopeBlockMock: ScopeBlockScope = {
  id: 'scope',
  type: 'scope',
  label: 'Relationship',
  fields_list: [
    {
      id: 'scope_properties_1',
      type: 'group',
      fields_list: [
        {
          id: 'scope_name',
          type: 'text',
          label: 'Name',
          placeholder: 'Start typing',
          required: true,
          value: 'Finances',
        },
        {
          id: 'scope_type',
          type: 'select',
          label: 'Type',
          placeholder: 'Select type',
          required: true,
          value: 'st_donation',
          options: [
            {
              label: 'Donation',
              value: 'st_donation',
            },
            {
              label: 'Joint Venture',
              value: 'st_joint_venture',
            },
            {
              label: 'Outsource',
              value: 'st_outsource',
            },
            {
              label: 'Partnership',
              value: 'st_partnership',
            },
            {
              label: 'Service',
              value: 'st_service',
            },
            {
              label: 'Vendor',
              value: 'st_vendor',
            },
          ],
        },
        {
          id: 'business_unit',
          type: 'select',
          label: 'Business Unit',
          placeholder: 'Enter business unit',
          required: true,
          value: 'bu_capital_markets',
          options: [
            {
              label: 'Capital Markets',
              value: 'bu_capital_markets',
            },
            {
              label: 'Corporate Banking',
              value: 'bu_corporate_banking',
            },
            {
              label: 'IT',
              value: 'bu_it',
            },
            {
              label: 'Retail Banking',
              value: 'bu_retail_banking',
            },
            {
              label: 'Wealth Management',
              value: 'bu_wealth_management',
            },
          ],
        },
        {
          id: 'cost_cadence',
          type: 'select',
          label: 'Cost Cadence',
          placeholder: 'Enter cost cadence',
          value: 'annual',
          options: [
            {
              label: 'Annual',
              value: 'annual',
            },
            {
              label: 'Monthly',
              value: 'monthly',
            },
          ],
        },
      ],
    },
    {
      id: 'scope_description',
      type: 'textarea',
      label: 'Description',
      placeholder: 'Enter description',
      required: true,
      value: random.words(20),
    },
  ],
};
