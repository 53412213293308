import { faker } from '@faker-js/faker';
import { buildMockTable, getRandomItemFromArray } from 'api/utils';
import { IHeaderItem, ITableRow } from 'app/containers/AbstractTable/types';
import { buildMockFilterData } from 'api/mockFilterData';
import { APIResponsePartyInsights } from 'api/insights/getPartInsightsAPI/index';
import {
  InsightIcon,
  InsightIndicatorType,
} from 'app/containers/RootInsights/types';

const rowsCount = 20;

const headerItems: IHeaderItem[] = [
  {
    id: 'indicator',
    label: 'Indicator',
    sublabel: '',
    orderable: true,
    mods: ['small'],
  },
  {
    id: 'empty_1',
    label: '',
    sublabel: '',
    orderable: false,
    mods: ['small'],
  },
  {
    id: 'risk_factor',
    label: 'Risk Factor',
    sublabel: '',
    orderable: true,
    mods: ['colspan2'],
  },
  {
    id: 'value',
    label: 'Value',
    sublabel: '',
    orderable: false,
    mods: ['width30'],
  },
  {
    id: 'empty_2',
    label: '',
    sublabel: '',
    orderable: false,
    mods: ['small'],
  },
];

const { internet, lorem } = faker;

const buildGroupRows = (count: number): ITableRow => {
  return {
    id: `${count}_tp_group_item_row`,
    cells: [
      {
        type: 'empty',
      },
      {
        type: 'insight-indicator',
        data: {
          icon: 'compliant',
        },
        mods: ['small'],
      },
      {
        type: 'empty',
      },
      {
        type: 'text',
        data: {
          text: 'bfc-jira.ciklum.net',
        },
        mods: [],
      },
      {
        type: 'text',
        data: {
          text: 'High-Severity Vulnerability in Last Observation',
        },
        mods: ['colspan2'],
      },
    ],
    order_value: 'AA',
    type: 'link-by-id',
  };
};

const groupRows = Array.from(
  Array(Math.floor(Math.random() * rowsCount) + 1).keys(),
).map(buildGroupRows);

const groupTable = buildMockTable([], groupRows);

const buildRows = (count: number): ITableRow => {
  const isGroup = count === 0;

  if (isGroup) {
    return {
      id: `${count}_tp_row_group`,
      cells: [
        {
          type: 'insight-group-indicator',
          data: {
            icon: getRandomItemFromArray([
              'compliant',
              'non_compliant',
              'insight',
            ]) as InsightIndicatorType,
          },
          mods: ['small'],
        },
        {
          type: 'insight-type-icon',
          data: {
            icon: getRandomItemFromArray([
              'online_asset',
              'person',
              '4th_party',
              'document',
              'questionnaire',
              'location',
              'control',
              'software_package',
            ]) as InsightIcon,
          },
          mods: ['small'],
        },
        {
          type: 'text',
          data: {
            text: internet.url(),
          },
          mods: ['colspan2'],
        },
        {
          type: 'insight-group-count-with-indicator',
          data: {
            text: '2 Insights',
            counters: [
              {
                type: getRandomItemFromArray([
                  'compliant',
                  'non_compliant',
                  'insight',
                ]) as InsightIndicatorType,
                count: '2',
              },
            ],
          },
        },
        {
          type: 'expanded-state',
        },
      ],
      type: 'group',
      table: groupTable,
    };
  }

  return {
    id: `${count}_tp_row`,
    cells: [
      {
        type: 'insight-indicator',
        data: {
          icon: getRandomItemFromArray([
            'compliant',
            'non_compliant',
            'insight',
          ]) as InsightIndicatorType,
        },
        mods: ['small'],
      },
      {
        type: 'insight-type-icon',
        data: {
          icon: getRandomItemFromArray([
            'online_asset',
            'person',
            '4th_party',
            'document',
            'questionnaire',
            'location',
            'control',
            'software_package',
          ]) as InsightIcon,
        },
        mods: ['small'],
      },
      {
        type: 'text',
        data: {
          text: lorem.words(5),
        },
        mods: ['colspan2'],
      },
      {
        type: 'text',
        data: {
          text: lorem.text(),
        },
        mods: ['colspan2'],
      },
    ],
    order_value: 'AA',
    type: 'link-by-id',
  };
};

const rows = Array.from(
  Array(Math.floor(Math.random() * rowsCount) + 1).keys(),
).map(buildRows);

const table = buildMockTable(headerItems, rows);

export const mock: APIResponsePartyInsights = {
  table,
  page_size: 10,
  page_sizes: [10, 20, 50],
  count: 125,
  page: 1,
  filter_data: buildMockFilterData(),
  legend: {
    items: [
      {
        id: 'compliant',
        label: 'Compliant',
        icon: 'compliant',
      },
      {
        id: 'insight',
        label: 'Insight',
        icon: 'insight',
      },
      {
        id: 'non_compliant',
        label: 'Non-compliant',
        icon: 'non_compliant',
      },
    ],
  },
};
